<template>
  <v-container>
    <v-row wrap justify-space-between>
      <v-col class="text-center justify-center pa-0">
        <v-card tile color="transparent" class="pt-4 elevation-0" style="transition: all 0.15s">
          <v-card-title class="font-weight-medium justify-center text-center display-2">
            Next Gen Connector Installers
          </v-card-title>
          <v-card-text class="py-5 subtitle-1">
            Pre-release (use at your own risk) and please
            <a href="https://speckle.community/t/new-connectors-open-beta/10961/1" target="_blank">
              provide feeback
            </a>
            !
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row wrap justify-space-between class="mt-10">
      <v-col class="align-center pa-0">
        <v-card tile color="transparent" class="pt-4 elevation-0" style="transition: all 0.15s">
          <v-card-title class="font-weight-light">Manual Installation (Windows only)</v-card-title>
          <v-card-text class="py-5 subtitle-1">
            Manual installers of our connectors are also available, please note that in some cases
            Manager is still needed to log into your account.
            <v-alert icon="mdi-alert" class="mt-5 mb-0" type="warning">
              By installing our connectors you agree to their
              <a href="https://speckle.systems/policy/terms" target="_blank">EULA.</a>
            </v-alert>
            <v-alert v-if="showPostDownloadThanks" icon="mdi-alert" class="mt-5 mb-0" type="info">
              Thanks for downloading! Check out our other next gen connectors if you want:
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="connectors.length > 0" class="pb-5">
      <v-col v-for="connector in connectors" :key="connector.slug" cols="12" xl="3" md="4" sm="6">
        <connector :connector="connector" />
      </v-col>
    </v-row>
    <v-row v-else align-content="center" align="center" justify="center">
      <v-col align-content="center" align="center" justify="center">
        <v-progress-circular class="ma-10" color="primary" indeterminate />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Connector from './Connector-v3'
import axios from 'axios'
import mixpanel from 'mixpanel-browser'

const spacesEndpoint = 'https://releases.speckle.dev'

export default {
  name: 'ConnectorsV3',
  components: {
    Connector
  },
  data: () => ({
    connectors: []
  }),
  computed: {
    showPostDownloadThanks() {
      return window.location.pathname.toLowerCase().includes('download')
    }
  },
  async mounted() {
    mixpanel.init('acd87c5a50b56df91a795e999812a3a4', {
      // eslint-disable-next-line camelcase
      api_host: 'https://analytics.speckle.systems'
    })
    mixpanel.track('Visit Releases')

    var localConnectorTags = [
      {
        name: 'Revit v3',
        slug: 'revit-v3',
        description:
          'Extract BIM data for further processing and visualisation, or dynamically create models from other CAD applications using Speckle for Revit! Supports Revit 2022, 2023, 2024 and 2025.',
        directDownload: true,
        featureImage: 'https://v1.speckle.systems/content/images/2021/06/revit.png'
      },
      {
        name: 'Rhino v3',
        slug: 'rhino-v3',
        description:
          'From sending and receiving geometry to scaffolding BIM models from simple geometry: Speckle for Rhino is here to help. Supports version 7 and 8 on Windows',
        directDownload: true,
        featureImage: 'https://v1.speckle.systems/content/images/2021/06/rhino.png'
      },
      {
        name: 'Autocad v3',
        slug: 'autocad-v3',
        description:
          'Exchange and extract geometry using our AutoCAD Connector. Supports versions 2022, 2023, 2024 and 2025.',
        directDownload: true,
        featureImage: 'https://v1.speckle.systems/content/images/2021/06/acad.png'
      },
      {
        name: 'Civil3D v3',
        slug: 'civil3d-v3',
        description: 'Exchange and extract geometry using our Civil3D Connector.',
        directDownload: true,
        featureImage: 'https://v1.speckle.systems/content/images/2021/06/civil.png'
      },
      {
        name: 'ArcGIS v3',
        slug: 'arcgis-v3',
        description:
          'Beta version of Speckle connector for ArcGIS. Creates a Speckle Toolbox in Geoprocessing tools. Supports ArcGIS Pro 3.3.',
        directDownload: true,
        featureImage: 'https://v1.speckle.systems/content/images/2022/08/arcgis.png'
      },
      {
        name: 'SketchUp v3',
        slug: 'sketchup-v3',
        description:
          'Be an early adopter and try the Speckle Connector for SketchUp. Send your SketchUp models out and receive models from other CAD/BIM apps. Supports versions 2021, 2022, 2023 and 2024.',
        directDownload: true,
        featureImage: 'https://v1.speckle.systems/content/images/2021/11/sketchup-brick-2.png'
      },
      {
        name: 'ArchiCAD v3',
        slug: 'archicad-v3',
        description:
          'Extract BIM data for further processing and visualization, or dynamically create models from other CAD applications using Speckle for Archicad! Supports Archicad 27 only for now.',
        directDownload: true,
        featureImage: 'https://v1.speckle.systems/content/images/2022/04/archicad-speckle-brick.png'
      },
      {
        name: 'Tekla Structures v3',
        slug: 'tekla-structures-v3',
        description:
          'Connect to Speckle with our Connector for Tekla Structures. Send data in customisable ways to enhance your workflows.',
        directDownload: true,
        featureImage: 'https://v1.speckle.systems/content/images/2022/02/teklastructures.png'
      },
      {
        name: 'Navisworks v3',
        slug: 'navisworks-v3',
        description:
          'Connect to Speckle with our Connector for Navisworks. Send data in customisable ways to enhance your workflows.',
        directDownload: true,
        featureImage: 'https://v1.speckle.systems/content/images/2022/12/Connector-Navis.png'
      },
      {
        name: 'Etabs v3',
        slug: 'etabs-v3',
        description:
          'Connect to Speckle with our Connector for ETABS. Send structural model data in customisable ways to enhance your workflows!',
        directDownload: true,
        featureImage: 'https://v1.speckle.systems/content/images/2021/10/ETABS.png'
      },
      {
        name: 'Power BI v3',
        slug: 'powerbi-v3',
        description:
          'Extract BIM data for further processing and visualization from any application using Speckle Power BI Connector!',
        directDownload: true,
        featureImage: 'https://v1.speckle.systems/content/images/2024/05/App-powerbi.png'
      }
    ]
    for (const tagIndex in localConnectorTags) {
      await this.getVersionsForTag(localConnectorTags[tagIndex])
    }
    this.connectors = localConnectorTags
  },
  methods: {
    async getVersionsForTag(tag) {
      console.log('Getting version for tag', tag)
      try {
        let response = await axios.get(`${spacesEndpoint}/manager2/feeds/${tag.slug}.json`)
        //console.log(response)
        let versions = response.data.Versions.sort(function (a, b) {
          return new Date(b.Date) - new Date(a.Date)
        })

        tag.stable = versions[0]
        //console.log(tag.stable)
        tag.versions = versions
      } catch (error) {
        tag.stable = null
        tag.versions = []
      }
    }
  }
}
</script>
